<!--
 * @Description: 招生业绩
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-10-07 10:27:39
 * @LastEditors: Seven
 * @LastEditTime: 2021-11-10 10:47:50
-->
<template>
  <div class="enrollment">
    <div style="width: 94%; margin: 0 auto">
      <Search
        :searchAllParam="searchAllParam"
        @search="search"
        @reset="reset"
        :searchParamList="searchParamList"
        code="count"
        />
    </div>
    <div class="line"></div>
    <div style="width: 94%;margin: 0 auto">
      <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            prop="recruitTeacherName"
            label="招生老师"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="dealNum"
            label="成交量"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="dealMoney"
            label="成交金额（元）"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="collectionMoney"
            label="回款金额（元）"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="refundMoney"
            label="退款金额（元）"
            min-width="100"
          ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Search from "components/search";
import { getQuerySchoolList, getQueryTeacherReport } from "api/count";
import { formatTimesHour } from "@/utils/timeDate";


export default {
  name: 'recruitTeacherReport',
  components: { Search },
  data() {
    return {
      searchParam: {},
      searchAllParam: [
        {
          name: "data",
          label: "日期",
          type: "date",
          ref: "follow",
          show: false,
          value: [formatTimesHour(new Date()), formatTimesHour(new Date())],
        },
        {
          name: "deptId",
          label: "校区",
          type: "select",
          option: [],
          value: "",
          text: "deptName",
          id: "deptId",
        },
        
      ],
      searchParamList: [],
      tableData: [],
      listLoading: false,
    };
  },
  mounted() {
    this.searchParamList = this.searchAllParam;
    this.getQuerySchoolList()
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },
    async getQuerySchoolList() {
      const { code, result} = await getQuerySchoolList()
      if(code === 0) {
        this.searchAllParam.map(item => {
          if(item.name === "deptId") {
            item.option = result
            item.value = result[0].deptId
          }
        })
      }
      this.search()
    },
    // 获取表格数据
    async getQuerySchoolFollowReport() {
      let params = {
        deptId: this.searchParam.deptId,
        endDate: this.searchParam.data[1],
        startDate: this.searchParam.data[0],
      }
      const { code, result, msg} = await getQueryTeacherReport(params)
      if (code == 0) {
        this.tableData = result.list
      } else {
        this.$message.error(msg)
      }
    },
    search() {
      this.searchParam = {
        deptId: '',
        data: []
      }
      this.searchAllParam.map(item => {
        if(item.value) {
          this.searchParam[item.name] = item.value
        }
      })
      console.log('this.searchParam', this.searchParam);
      this.getQuerySchoolFollowReport()
    },
    reset() {
      this.searchAllParam.map(item => {
        if(item.value) {
          this.searchParam[item.name] = ""
          item.value = ""
        }
      })
      this.tableData = []
      this.getQuerySchoolFollowReport()
    },
  }
};
</script>
